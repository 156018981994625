<template>
  <v-card>
    <v-card-title class="timeline-title">
      <h2 class="white--text">Latest Activity</h2>
    </v-card-title>
    <dynamic-scroller
      style="height: 300px;"
      v-if="sortedItems.length > 0"
      :items="sortedItems"
      :min-item-size="125"
      class="scroller"
    >
      <template v-slot:default="{ item }">
        <dynamic-scroller-item
          :item="item"
          :active="true"
          :size-dependencies="[
            item.problem_statement,
            item.summary,
            item.outcome,
          ]"
          :data-index="item.id"
        >
          <v-timeline dense class="custom-timeline">
            <v-timeline-item
              :color="getDotColor(item)"
              :key="item.id"
              right
              :small="
                (!colorCodeByCenter && !('priority_area' in item)) ||
                  (item.start_date && !item.completed_date && colorCodeByCenter)
              "
            >
              {{
                (item.created_date || item.completed_date || item.start_date)
                  | formatDate
              }}
              <div v-if="item.completed_date">
                <div class="text-h6">
                  Project Completed:
                  <a
                    :href="`/projects/${item.id}`"
                    style="color: #0093ad; font-weight: 600"
                    >{{ item.name }}</a
                  >
                </div>
                <p class="custom-text-truncate">
                  {{ item.outcome || item.summary }}
                </p>
              </div>
              <div v-else-if="item.start_date">
                <div class="text-h6">
                  Project Started:
                  <a
                    :href="`/projects/${item.id}`"
                    style="color: #0093ad; font-weight: 600"
                    >{{ item.name }}</a
                  >
                </div>
                <p class="custom-text-truncate">
                  {{ item.problem_statement || item.summary }}
                </p>
              </div>
              <div v-else>
                <div class="text-h6">
                  File Added:
                  <span
                    v-if="downloading && item.id === downloadingAttachmentId"
                  >
                    <v-progress-circular
                      indeterminate
                      size="20"
                      color="primary"
                    ></v-progress-circular>
                  </span>
                  <span v-else>
                    <a
                      href="#"
                      @click.prevent="downloadFile(item)"
                      style="color: #0093ad; font-weight: 600"
                    >
                      {{ item.name }}
                    </a>
                  </span>
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </dynamic-scroller-item>
      </template>
    </dynamic-scroller>
    <div v-else class="empty-timeline-message">
      No activity to display.
    </div>
  </v-card>
</template>

<style>
.empty-timeline-message {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #888;
}
.timeline-title {
  padding: 10px;
  background-color: #2e2e2e;
}

.custom-timeline {
  /* margin: 10px; */
  background: #ffffff;
  padding: 0px 10px 0vw 10px;
  padding-top: calc(0.5vw + 10px) !important;
}

.v-timeline-item__dot {
  transform: translateX(-10px);
}
.theme--light.v-timeline .v-timeline-item__dot {
  background: #eeeeee;
}
</style>

<script>
import axios from "axios";
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

export default {
  props: {
    filesEndPoint: {
      type: String,
      required: false,
      default: "attachments?",
    },
    projectsEndPoint: {
      type: String,
      required: false,
      default: "projects?",
    },

    numItems: {
      type: Number,
      required: false,
      default: 3,
    },
    colorCodeByCenter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DynamicScroller,
    DynamicScrollerItem,
  },
  data: () => ({
    files: [],
    projects: [],
    centers: {
      BCPI: "var(--blue)",
      GovEx: "var(--purple)",
    },
    downloading: false,
    downloadingAttachmentId: null,
  }),

  computed: {
    sortedItems() {
      const allItems = [...this.projects, ...this.files];
      const currentDate = new Date();

      const filteredItems = allItems.filter((item) => {
        const itemDate = this.getLatestDate(item);
        return itemDate !== null && itemDate <= currentDate;
      });

      if (this.files.length > 0) {
        const sortedItems = filteredItems.sort((a, b) => {
          const laterDateA = this.getLatestDate(a);
          const laterDateB = this.getLatestDate(b);
          if (laterDateA < laterDateB) return 1;
          if (laterDateA > laterDateB) return -1;
          return 0;
        });

        return sortedItems;
      } else {
        return filteredItems;
      }
    },

    calculatedItemHeight() {
      // Calculate the number of lines for outcome, problem_statement, or summary
      const numLines = this.sortedItems.map((item) => {
        let text = item.outcome || item.problem_statement || item.summary || "";
        return text.split("\n").length;
      });

      // Determine the item height based on the number of lines
      const itemHeight = numLines.map((lines) => {
        if (lines === 1) {
          return 140;
        } else if (lines > 1) {
          return 168;
        } else {
          return 125;
        }
      });

      // Return the maximum item height among all items
      return Math.max(...itemHeight);
    },
  },
  async created() {
    await this.getFiles(); // Wait for getFiles() to finish
    await this.getProjects(); // Wait for getProjects() to finish
  },

  methods: {
    getFiles: async function() {
      let url = `${this.filesEndPoint}&ordering=-created_date`;
      if (!this.colorCodeByCenter) {
        while (url) {
          let response = await this.$api.get(url);
          await this.files.push.apply(this.files, response.data.results);
          url = response.data.next;
        }
      }
    },

    getProjects: async function() {
      let url = `${this.projectsEndPoint}&ordering=date`;
      while (url) {
        let response = await this.$api.get(url);
        await this.projects.push.apply(this.projects, response.data.results);
        url = response.data.next;
      }
    },

    getLatestDate(item) {
      const startDate = item.start_date ? new Date(item.start_date) : null;
      const completedDate = item.completed_date
        ? new Date(item.completed_date)
        : null;

      const projectDate =
        startDate && completedDate
          ? Math.max(startDate, completedDate)
          : startDate || completedDate;
      const fileDate = item.created_date ? new Date(item.created_date) : null;
      return projectDate && fileDate
        ? Math.max(projectDate, fileDate)
        : projectDate || fileDate;
    },

    getDotColor(item) {
      if (this.colorCodeByCenter) {
        return this.centers[item.award_center] || "var(--light-grey)";
      } else {
        let color = !("priority_area" in item)
          ? "var(--aqua)" // Change color to aqua for files
          : item.status === "COMP" || item.status === "CNCLD"
          ? "var(--dark-grey)"
          : "var(--green)";
        return color;
      }
    },
    downloadFile(attachment) {
      this.downloadingAttachmentId = attachment.id;
      this.downloading = true;
      axios
        .get(
          `${process.env.VUE_APP_API}attachments/${attachment.id}/download/`,
          { responseType: "arraybuffer" }
        )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = attachment.name;
          link.click();
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        })
        .finally(() => {
          this.downloading = false; // Reset downloading state to false
        });
    },
  },
};
</script>
